var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-company-sidebar","visible":_vm.isAddNewCompanySidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-add-new-company-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.companyData.id ? 'Update' : 'Add New')+" Company ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){_vm.resetForm();
          hide();}}})],1),_c('validation-observer',{ref:"newCompanyForm"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm()}}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"name":"name","placeholder":"Name"},model:{value:(_vm.companyData.name),callback:function ($$v) {_vm.$set(_vm.companyData, "name", $$v)},expression:"companyData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","name":"email","placeholder":"Email"},model:{value:(_vm.companyData.email),callback:function ($$v) {_vm.$set(_vm.companyData, "email", $$v)},expression:"companyData.email"}})],1),_c('b-form-group',{attrs:{"label":"Contact Num","label-for":"contact-num"}},[_c('b-form-input',{attrs:{"id":"contact-num","name":"contact-num"},model:{value:(_vm.companyData.contactTel),callback:function ($$v) {_vm.$set(_vm.companyData, "contactTel", $$v)},expression:"companyData.contactTel"}})],1),_c('validation-provider',{attrs:{"name":"Pays Admin Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Pays Admin Fee","label-for":"pays-admin-fee"}},[_c('v-select',{attrs:{"options":[
                { label: 'Yes', value: true },
                { label: 'No', value: false } ],"label":"label","reduce":function (options) { return options.value; },"input-id":"pays-admin-fee"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}},{key:"selected-option",fn:function(ref){
              var label = ref.label;
              var value = ref.value;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50",class:value ? 'bg-success' : 'bg-secondary',staticStyle:{"height":"10px","width":"10px"}}),_c('span',[_vm._v(" "+_vm._s(label))])]}}],null,true),model:{value:(_vm.companyData.paysAdminFee),callback:function ($$v) {_vm.$set(_vm.companyData, "paysAdminFee", $$v)},expression:"companyData.paysAdminFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1),_c('b-form-group',{attrs:{"label":"Is Gib Air","label-for":"is-gib-air"}},[_c('b-form-checkbox',{attrs:{"name":"is-gib-air","switch":""},on:{"change":function($event){return _vm.companyTypeHasChange('gibair')}},model:{value:(_vm.companyData.isGibAir),callback:function ($$v) {_vm.$set(_vm.companyData, "isGibAir", $$v)},expression:"companyData.isGibAir"}})],1),_c('b-form-group',{attrs:{"label":"Is GHA","label-for":"is-gha"}},[_c('b-form-checkbox',{attrs:{"name":"is-gha","switch":""},on:{"change":function($event){return _vm.companyTypeHasChange('gha')}},model:{value:(_vm.companyData.isGha),callback:function ($$v) {_vm.$set(_vm.companyData, "isGha", $$v)},expression:"companyData.isGha"}})],1),_c('b-form-group',{attrs:{"label":"Is Cancelled Vouchers","label-for":"is-cancelled"}},[_c('b-form-checkbox',{attrs:{"name":"is-cancelled","switch":""},on:{"change":function($event){return _vm.companyTypeHasChange('cancelled')}},model:{value:(_vm.companyData.isCancelled),callback:function ($$v) {_vm.$set(_vm.companyData, "isCancelled", $$v)},expression:"companyData.isCancelled"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_vm._e(),_vm._v(" "+_vm._s(_vm.companyData.id ? 'Update' : 'Add')+" ")],1),_c('b-button',{attrs:{"type":"button","variant":"outline-secondary"},on:{"click":function($event){_vm.resetForm();
              hide();}}},[_vm._v(" Cancel ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }