<template>
  <b-sidebar
    id="add-new-company-sidebar"
    :visible="isAddNewCompanySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-company-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ companyData.id ? 'Update' : 'Add New' }} Company
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="
            resetForm();
            hide();
          "
        />
      </div>

      <validation-observer ref="newCompanyForm">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="validationForm()"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input
                id="name"
                v-model="companyData.name"
                :state="errors.length > 0 ? false : null"
                name="name"
                placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="companyData.email"
              name="email"
              placeholder="Email"
            />
          </b-form-group>

          <b-form-group
            label="Contact Num"
            label-for="contact-num"
          >
            <b-form-input
              id="contact-num"
              v-model="companyData.contactTel"
              name="contact-num"
            />
          </b-form-group>

          <validation-provider
            #default="{ errors }"
            name="Pays Admin Fee"
            rules="required"
          >
            <b-form-group
              label="Pays Admin Fee"
              label-for="pays-admin-fee"
            >
              <v-select
                v-model="companyData.paysAdminFee"
                :options="[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false },
                ]"
                label="label"
                :reduce="(options) => options.value"
                input-id="pays-admin-fee"
              >
                <template #option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>

                <template #selected-option="{ label, value }">
                  <div
                    class="rounded-circle d-inline-block mr-50"
                    :class="value ? 'bg-success' : 'bg-secondary'"
                    style="height: 10px; width: 10px"
                  />
                  <span> {{ label }}</span>
                </template>
              </v-select>

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>

            <b-form-group
              label="Is Gib Air"
              label-for="is-gib-air"
            >
              <b-form-checkbox
                @change="companyTypeHasChange('gibair')"
                v-model="companyData.isGibAir"
                name="is-gib-air"
                switch
              />
            </b-form-group>

            <b-form-group
              label="Is GHA"
              label-for="is-gha"
            >
              <b-form-checkbox
                @change="companyTypeHasChange('gha')"
                v-model="companyData.isGha"
                name="is-gha"
                switch
              />
            </b-form-group>

            <b-form-group
              label="Is Cancelled Vouchers"
              label-for="is-cancelled"
            >
              <b-form-checkbox
                @change="companyTypeHasChange('cancelled')"
                v-model="companyData.isCancelled"
                name="is-cancelled"
                switch
              />
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isLoading"
            >
              <b-spinner
                v-if="isLoading"
                small
                variant="light"
              />
              {{ companyData.id ? 'Update' : 'Add' }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="
                resetForm();
                hide();
              "
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton,
  BFormCheckbox,
  BForm,
  BFormGroup,
  BFormInput,
  BSidebar,
  BSpinner,
} from 'bootstrap-vue';
import { ref } from '@vue/composition-api';
import { required } from '@validations';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import useCompany from '@/modules/company/composables/useCompany';
import vSelect from 'vue-select';

export default {
  props: {
    isAddNewCompanySidebarActive: {
      type: Boolean,
      required: true,
    },
    company: {
      type: Object,
      require: true,
    },
  },
  setup(props, ctx) {
    const { createCompany, updateCompany } = useCompany();
    const companyData = ref(props.company);
    const newCompanyForm = ref(null);
    const isLoading = ref(false);

    const resetForm = () => {
      newCompanyForm.value.reset();
    };

    const validationForm = async () => {
      if (isLoading.value) return;

      isLoading.value = true;

      const valid = await newCompanyForm.value.validate();
      if (!valid) {
        isLoading.value = false;
        return;
      }

      const result = companyData.value.id
        ? await updateCompany(companyData.value)
        : await createCompany(companyData.value);

      if (result !== null) {
        ctx.emit('form-submitted');
      }

      isLoading.value = false;
    };

    const companyTypeHasChange = (type) => {
      switch (type) {
        case 'gibair':
          companyData.value.isGha = false;
          companyData.value.isCancelled = false;
          break;
        case 'gha':
          companyData.value.isGibAir = false;
          companyData.value.isCancelled = false;
          break;
        case 'cancelled':
          companyData.value.isGha = false;
          companyData.value.isGibAir = false;
          break;
      }
    };

    return {
      // Reactive
      companyData,
      newCompanyForm,
      isLoading,

      // Method
      resetForm,
      validationForm,
      companyTypeHasChange,

      // Validator
      required,
    };
  },
  components: {
    BButton,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BSidebar,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BSpinner,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-company-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
