import { computed, ref, watch } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import store from '@/store';
import useUtils from "@/composables/useUtils";

const useCompanyList = () => {
  const toast = useToast();
  const {formatCurrency} = useUtils();

  const perPageOptions = [10, 25, 50, 100];
  const tableColumns = [
    { key: 'name', sortable: true },
    { key: 'email', sortable: true },
    { key: 'contactTel', label: 'Contact Num' },
    {
      key: 'balance',
      formatter: (val) => formatCurrency(val),
    },
    { key: 'paysAdminFee', label: 'pays admin fee' },
    { key: 'actions' },
  ];

  const pageNumber = ref(1);
  const orderDesc = ref(true);
  const pageSize = ref(10);
  const refCompaniesTable = ref(null);
  const searchQuery = ref(null);
  const orderBy = ref('id');
  const totalCompanies = ref(0);

  const dataMeta = computed(() => {
    const localItemsCount = refCompaniesTable.value
      ? refCompaniesTable.value.localItems.length
      : 0;

    return {
      from: pageSize.value * (pageNumber.value - 1) + (localItemsCount ? 1 : 0),
      to: pageSize.value * (pageNumber.value - 1) + localItemsCount,
      of: totalCompanies.value,
    };
  });

  watch([pageNumber, pageSize], () => {
    refCompaniesTable.value.refresh();
  });

  const getCompaniesList = (_, callback) => {
    const params = {
      orderBy: orderBy.value,
      orderDesc: orderDesc.value,
      pageSize: pageSize.value,
      pageNumber: pageNumber.value,
      searchQuery: searchQuery.value,
    };

    store
      .dispatch('company/getList', params)
      .then(({ companies, total }) => {
        callback(companies);
        totalCompanies.value = total;
      })
      .catch(() => {
        toast.error({
          component: ToastificationContent,
          props: {
            title: 'Error fetching company list',
            icon: 'XCircleIcon',
          },
        });
      });
  };

  return {
    // Const
    perPageOptions,
    tableColumns,

    // Reactive
    pageNumber,
    orderDesc,
    pageSize,
    refCompaniesTable,
    searchQuery,
    orderBy,
    totalCompanies,

    // Computed
    dataMeta,

    // Method
    getCompaniesList,
  };
};

export default useCompanyList;
